<template>
	<div>
		<div class="header filter">
			<div class="cartel_top">
				<div class="top top_mafi f-left">
					<div class="util">
						<button
							@click="$emit('goBack')"
							class="arrow_white">
							<i class="icon-arrow-left"></i>
						</button>
					</div>
					<div class="page_tit_area">
						<h2 class="page_tit">{{  program.title }}</h2>
						<span class="page_subtit">{{  item_cartel.title }}</span>
					</div>
					<div class="save">
						<button
							@click="$emit('to', { name: 'mafia057', params: {idx: $route.params.idx, from: $route.name}})"
							class="btn_save">{{  $language.common.invite }}</button>
					</div>
				</div>
			</div>
		</div>
		<!-- //고정해더 -->

		<div class="section_wrap pt-70">
			<div class="ct_sticky tab_serch">
				<div class="container">
					<div class="row">
			<!-- 탭선택 -->
						<div class="member_tab">
							<div class="list_tab pt-20">
								<ul>
									<!-- TODO : 활성화 탭 on 클래스 -->
									<li
										:class="{on: item_search.status === 0}"
									><a
										@click="item_search.status = 0; getWaitMember()"
										class="btn_l btn_fill_lightgray"
									>{{  $language.common.waiting_approval }}</a></li>
									<li
										:class="{on: item_search.status == 1}"
									><a
										@click="item_search.status = 1; getData()"
										class="btn_l btn_fill_lightgray"
									>{{  $language.common.member }}</a></li>
									<li
										:class="{on: item_search.status == 2}"
									><a
										@click="item_search.status = 2; getStopMember()"
										class="btn_l btn_fill_lightgray"
									>{{  $language.common.stopped_member }}</a></li>
								</ul>
							</div>
						</div>

						<!-- 멤버 검색 -->
						<div class="member_serch pt-20">
							<div class="bright_input">
								<h2 class="hide">{{  $language.common.search_member }}</h2>
								<div class="bright_input">
									<div class="input_utill">
										<input
											v-model="item_search.search_value"
											type="text" id="cartelName" :placeholder="$language.common.enter_member_name" maxlength="20"
											@keyup.enter="getData"
										>
										<div class="input_utill_area">
											<div class="btn_del">
												<!-- TODO : 텍스트 입력시에만 .active 로 닫기 버튼 활성화 -->
												<button
													v-if="item_search.search_value"
													class="active"
													@click="item_search.search_value = ''; getData()"
												><span class="hide">{{  $language.common.close }}</span></button>
											</div>
											<div class="util">
												<button
													@click="getData"
													class="img_button" type="submit" name="click"
												><span class="hide">{{  $language.common.search }}</span></button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- //멤버 검색 -->
					</div>
				</div>
			</div>
			<!-- //탭선택 -->
			<!-- 승인대기 멤버 -->
			<div class="subindex_head ">
				<div class="underboss pt-30">
					<div class="container">
						<div
							v-if="item_search.status === 0"
							class="row"
						>
							<div class="tit_count clear">
								<h2 class="itemlist_head col_primary">{{  $language.common.waiting_approval }}</h2>
								<span class="m_count">{{ tot_member_count }}</span>
							</div>
							<div
								v-if="items_wait.length > 0"
								class="underboss_area thumbnail_list ct_invite"
							>
								<!-- 승인대기멤버 -->
								<div
									v-for="(item_wait, index) in items_wait"
									:key="'wait_' + index"
									class="ct_invite_list"
								>
									<div class="circle_thumbnail_item">
										<div class="clear">
											<div class="picture">
												<img
													v-if="item_wait.profle_nft_card_img_url"
													:src="$request.upload_url(item_wait.profle_nft_card_img_url)"
												/>
												<img
													v-else
													:src="require('@/assets/image/@profile_inner.png')" :alt="$language.cartel.recommend_cartel"
												>
											</div>
											<div class="text_area">
												<strong>{{  item_wait.nickname }}</strong>
												<p class="textzone">
													{{ item_wait.cartl_member_grade_name }}
												</p>
											</div>
										</div>
										<div class="ct_invite_btn under_btn">
											<div>
												<button
													@click="onConfirm('join_confirm', item_wait)"
													class="btn_outline_blue btn_s"
												>{{  $language.common.approval }}</button>
												<button
													@click="onConfirm('join_cancel', item_wait)"
													class="btn_outline_blue btn_s"
												>{{  $language.common.delete }}</button>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div
								v-else
							>
								<div class="list_none" style="overflow: hidden; margin-top: 50%">
									<img :src="require('@/assets/image/list_none.png')" alt="Not Found History">
									<span>{{  $language.common.no_lookup_member }}</span>
								</div>
							</div>
						</div>
						<div
							v-else-if="item_search.status === 2"
							class="row"
						>
							<div class="tit_count clear">
								<h2 class="itemlist_head col_primary">{{  $language.common.stopped_member }}</h2>
								<span class="m_count">{{ tot_member_count }}명</span>
							</div>
							<div class="underboss_area thumbnail_list ct_invite">
								<!-- 승인대기멤버 -->
								<template
									v-if="items_deactivate.length > 0"
								>
								<div
									v-for="(item_deactivate, index) in items_deactivate"
									:key="'wait_' + index"
									class="ct_invite_list"
								>
									<div class="circle_thumbnail_item">
										<div class="clear">
											<div class="picture">
												<img
													v-if="item_deactivate.profle_nft_card_img_url"
													:src="$request.upload_url(item_deactivate.profle_nft_card_img_url)"
												/>
												<img
													v-else
													:src="require('@/assets/image/@profile_inner.png')" :alt="$language.cartel.recommend_cartel"
												>
											</div>
											<div class="text_area">
												<strong>{{  item_deactivate.nickname }}</strong>
												<p class="textzone">
                          {{  $language.common.remaining_day }} {{ item_deactivate.cartl_member_grade_name }}
													{{ item_deactivate.stop_enddate_count }} {{  $language.common.day }}
												</p>
											</div>
										</div>
										<div class="ct_invite_btn under_btn">
											<div>
												<button
													@click="onConfirm('safe', item_deactivate)"
													class="btn_outline_blue btn_s"
												>{{  $language.common.termination }}</button>
											</div>
										</div>
									</div>
								</div>
								</template>
								<div
									v-else
								>
									<div class="list_none" style="overflow: hidden; margin-top: 50%">
										<img :src="require('@/assets/image/list_none.png')" alt="Not Found History">
										<span>{{  $language.common.no_lookup_member }}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- //승인대기 멤버 -->
			<!-- 멤버 -->
			<div
				v-if="item_search.status == 1"
			>
				<div class="underboss_member ">
					<div class="container">
						<div class="row">
							<div class="tit_count clear">
								<h2 class="itemlist_head col_primary">{{  $language.common.member }}</h2>
								<span class="m_count">{{ tot_member_count }}</span>
							</div>
							<ul class="underboss_member_area thumbnail_list ct_invite">
								<template
									v-if="items_member.length > 0"
								>
								<li
									v-for="(item_member, index) in items_member"
									:key="'member_' + index"
								>
									<div class="ct_invite_list">
										<div class="circle_thumbnail_item">
											<div class="clear">
												<div class="picture">
													<img
														v-if="item_member.profle_nft_card_img_url"
														:src="$request.upload_url(item_member.profle_nft_card_img_url)"
													/>
													<img
														v-else
														:src="require('@/assets/image/@profile_inner.png')" :alt="$language.cartel.recommend_cartel"
													>
												</div>
												<div class="text_area">
													<strong>{{  item_member.nickname }}</strong>
													<p class="textzone">
														{{ item_member.cartl_member_grade_name}}
													</p>
												</div>
											</div>
											<div
												class="ct_invite_btn text-center" style="display: flex; flex-direction: column"
											>
												<template
													v-if="item_member.cartl_member_div_code == 'CA02500003'"
												>
												<button

													@click="onConfirm('deactivate', item_member)"
													class="btn_outline_blue btn_s" >{{  $language.state_02.CA00300005 }}</button>
												<br/>
												<button
													@click="onConfirm('out', item_member)"
													class="btn_outline_blue btn_s mt-5"
												>{{  $language.state_02.CA00300007 }}</button>
												</template>
											</div>
										</div>
									</div>
								</li>
								</template>
								<li
									v-else
								>
									<div class="list_none" style="overflow: hidden; margin-top: 50%">
										<img :src="require('@/assets/image/list_none.png')" alt="Not Found History">
										<span>{{  $language.common.no_lookup_member }}</span>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<popup_confirm
			v-if="is_confirm"

			@click="doConfirm"
			@cancel="cancelConfirm"
		>
			<template
				v-slot:title
			>{{ confirm_option.name }}</template>
			<template
				v-slot:main-txt
			>
				{{ confirm_option.main_txt }}
			</template>
			<template
				v-slot:sub-txt
			>
				{{ confirm_option.sub_txt }}
				<ul
					v-if="confirm_type == 'deactivate'"
				>
					<li
						v-for="deact in items_deact"
						:key="'deact_' + deact.code"
						style="padding: 10px"
					><label><input v-model="deact_days" type="radio" :value="deact.code" /> {{  deact.key }}{{  $language.common.days_stop_activity }}</label></li>
				</ul>
			</template>
		</popup_confirm>
	</div>
</template>

<script>
import popup_confirm from "@/view/Layout/PopupConfirm";
export default {
	name: 'mafia091'
	, props: ['user']
	, components: {popup_confirm}
	, data: function(){
		return {
			program: {
				name: this.$language.common.manageMember
				, title: this.$language.common.manageMember
				, type: 'cartel_sub'
				, not_header: true
				, not_footer: true
			}
			, items_member: []
			, items_wait: []
			, item_cartel: {}
			, item_search: this.$storage.init({
				search_value: ''
				, page_number: this.$language.base.page_number
				, pagerecnum: this.$language.base.pagerecnum
				, status: 0
			})
			, is_confirm: false
			, confirm_type: ''
			, item_member: {}
			, items_deactivate: []
			, items_deact: [
				{ code: 'CA01600001', key: 7}
				, { code: 'CA01600002', key: 15}
				, { code: 'CA01600003', key: 30}
			]
			, deact_days: 7
			, tot_member_count: 0
		}
	}
	, computed: {
		confirm_option: function() {
			console.log('this.confirm_type', this.confirm_type)
			let t = {
				name: this.$language.common[this.confirm_type].name
				, main_txt: this.$language.common[this.confirm_type].main_txt
				, sub_txt: this.item_member.user_nick
			}

			return t
		}
	}
	, methods: {
		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_member_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, srchtext: this.item_search.search_value
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					this.items_member = result.data.cartl_member_list
					this.tot_member_count = result.data.tot_member_count
					this.$storage.setQuery(this.item_search)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_search = true
				this.$bus.$emit('on', false)
			}
		}
		, getWaitMember: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_member_wait_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, srchext: this.item_search.search_value
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					this.items_wait = result.data.approval_waiting_cartl_member_list
					this.tot_member_count = result.data.tot_member_count
					this.$storage.setQuery(this.item_search)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_search = true
				this.$bus.$emit('on', false)
			}
		}
		, getStopMember: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_member_stop_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, srchext: this.item_search.search_value
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					this.items_deactivate = result.data.cartl_stop_member_list
					this.tot_member_count = result.data.tot_member_count
					this.$storage.setQuery(this.item_search)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_search = true
				this.$bus.$emit('on', false)
			}
		}
		,getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, notice_mttrs_list_page: { page_number: 1, pagerecnum: 10}
						, post_list_page: { page_number: 1, pagerecnum: 10}
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postInvite: async function(member_id){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_invite
					, data: {
						cartel_id: this.item_cartel.id
						, member_id: member_id
					}
					, name: 'postInvite'
				})

				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: this.$language.common.succeedInvite})
					await this.getCartel()
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,onConfirm: function(type, item){
			this.confirm_type = type
			this.item_member = item
			this.is_confirm = true
		}

		, postCartelMemberConfirm: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_cartel_member_confirm
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, approval_member_number: this.item_member.member_number
					}
					, type: true
				})

				if(result.success){
					await this.getWaitMember()
					this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})
				}else{
					throw result.message
				}
			}catch (e){
				console.log(e)
			}finally {
				this.is_confirm = false
				this.$bus.$emit('on', false)
			}
		}

		, postCartelMemberCancel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_cartel_member_cancel
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, approval_member_number: this.item_member.member_number
					}
					, type: true
				})

				if(result.success){
					await this.getWaitMember()
					this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})
				}else{
					throw result.message
				}
			}catch (e){
				console.log(e)
			}finally {
				this.is_confirm = false
				this.$bus.$emit('on', false)
			}
		}
		, postCartelMemberOut:async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_cartel_member_drop
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, withddt_member_number: this.item_member.member_number
					}
					, type: true
				})

				if(result.success){
					await this.getData()
					this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})
				}else{
					throw result.message
				}
			}catch (e){
				console.log(e)
			}finally {
				this.is_confirm = false
				this.$bus.$emit('on', false)
			}
		}
		, postCartelMemberStop: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_cartel_member_stop
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, stop_member_number: this.item_member.member_number
						, cartl_stop_term_code: this.deact_days
					}
					, type: true
				})

				if(result.success){
					await this.getData()
					this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})
				}else{
					throw result.message
				}
			}catch (e){
				console.log(e)
			}finally {
				this.is_confirm = false
				this.$bus.$emit('on', false)
			}
		}

		, postCartelMemberSafe: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_cartel_member_stop_cancel
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, release_member_number: this.item_member.member_number
					}
					, type: true
				})

				if(result.success){
					await this.getStopMember()
					this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})
				}else{
					throw result.message
				}
			}catch (e){
				console.log(e)
			}finally {
				this.is_confirm = false
				this.$bus.$emit('on', false)
			}
		}
		, postCartelMemberStatus: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_cartel_member_status
					, data: {
						cartel_id: this.$route.params.idx
						, user_id: this.item_member.user_id
						, status: this.item_member.status
						, deactivated_days: this.deact_days
					}
					, name: 'postCartelMemberStatus'
				})

				if(result.success){
					await this.getData()
					this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})
				}else{
					throw result.message
				}
			}catch (e){
				console.log(e)
			}finally {
				this.is_confirm = false
				this.$bus.$emit('on', false)
			}
		}
		, doConfirm: function(){
			switch (this.confirm_type){
				case 'join_confirm':
					this.item_member.status = 1
					this.postCartelMemberConfirm()
					break
				case 'join_cancel':
					this.item_member.status = -1
					this.postCartelMemberCancel()
					break
				case 'out':
					this.item_member.status = 3
					this.postCartelMemberOut()
					break
				case 'deactivate':
					this.item_member.status = 2
					this.postCartelMemberStop()
					break
				case 'safe':
					this.item_member.status = 1
					this.postCartelMemberSafe()
					break
			}

		}
		, cancelConfirm: function(){
			this.is_confirm = false
		}
	}
	,async created() {
		this.$bus.$emit('onLoad', this.program)

		await this.getCartel()

		if(this.item_search.status == 0) {
			await this.getWaitMember()
		}else if(this.item_search.status == 2){
			await this.getStopMember()
		}else{
			await this.getData()
		}
	}

}
</script>

<style>
	.text-center { text-align: center}
</style>